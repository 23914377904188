import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { uploadFile } from "helpers/func";
import { useAlert } from "components/hooks";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { defaultPageInfo } from "helpers/mockData";
import MainModal from "components/modals/MainModal";
import { CustomButton } from "components/Utilities";
import DownloadIcon from "@mui/icons-material/Download";
import ConfirmModal from "components/modals/ConfirmModal";
import { getCompany, getEnrolles } from "components/graphQL/useQuery";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import EnrolleesTable from "components/tables/EnrolleesTable";
import { uploadEnrollees } from "components/graphQL/Mutation";
import AddEditHMOEnrolleeModal from "components/modals/AddEditEnrolleeModal";
import { UploadEnrolleeListForm } from "components/Forms/UploadEnrolleeListForm";

const CompanyEnrollees = () => {
  const theme = useTheme();
  const { companyId } = useParams();
  const { displayAlert, getErrorMsg } = useAlert();
  const [uploadBulkEnrollees] = useMutation(uploadEnrollees);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [companyEnrollees, setCompanyEnrollees] = useState([]);
  const providerId = localStorage.getItem("partnerProviderId");
  const [uploadListModal, setUploadListModal] = useState(false);
  const [addEnrolleeModal, setAddEnrolleeModal] = useState(false);
  const [confirmUploadModal, setConfirmUploadModal] = useState(false);
  const { data: CompanyData } = useQuery(getCompany, {
    variables: { companyId },
  });
  const companyName = CompanyData?.getCompany?.name || "";

  const [
    fetchEnrollees,
    { error, loading, refetch, variables, networkStatus },
  ] = useLazyQuery(getEnrolles, {
    notifyOnNetworkStatusChange: true,
    variables: {
      providerId: providerId,
      companyId,
    },
  });

  const enrolleesParams = {
    enrollees: companyEnrollees,
    setEnrollees: setCompanyEnrollees,
    pageInfo,
    setPageInfo,
    fetchEnrollees,
    error,
    loading,
    refetch,
    variables,
    networkStatus,
  };

  const fetchData = (fetch) => {
    fetch({
      variables: {
        providerId: providerId,
        first: 10,
      },
    })
      .then(({ data }) => {
        setCompanyEnrollees(data?.getEnrollees?.data || []);
        setPageInfo(data?.getEnrollees?.pageInfo || {});
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData(fetchEnrollees);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEnrollees, providerId]);

  const darkButton = {
    background: theme.palette.primary.main,
    hover: theme.palette.primary.light,
    active: theme.palette.primary.dark,
  };

  const lightButton = {
    background: "#fff",

    hover: theme.palette.primary.light,
    active: theme.palette.primary.dark,
  };

  const downloadButton = {
    background: theme.palette.common.blue,

    hover: theme.palette.common.lightBlue,
    active: theme.palette.primary.dark,
  };

  return (
    <Grid>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
        marginBottom="2rem"
        marginTop="2rem"
      >
        <Grid item>
          <Typography variant="h1">{companyName}</Typography>
        </Grid>
        <Grid item>
          <Grid container columnGap={1} rowGap={1}>
            <Grid item>
              <CustomButton
                startIcon={<FileUploadIcon />}
                title="Upload List"
                type={lightButton}
                textColor="#2D2F39"
                textColorOnHover="#ffffff"
                onClick={() => setConfirmUploadModal(true)}
              />
            </Grid>
            <Grid item>
              <CustomButton
                startIcon={<AddIcon />}
                title="Add  Enrollee"
                type={darkButton}
                onClick={() => setAddEnrolleeModal(true)}
              />
            </Grid>
            <Grid item>
              <a
                style={{ textDecoration: "none" }}
                href="downloads/enrollee-upload-template.csv"
                rel="noreferrer"
                download=""
              >
                <CustomButton
                  startIcon={<DownloadIcon />}
                  title="Download template"
                  type={downloadButton}
                  textColor="#fff"
                  textColorOnHover="#3E5EA9"
                  onClick={() => null}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <EnrolleesTable enrolleesParams={enrolleesParams} />
      </Grid>

      {/* MODALS */}
      <Grid>
        {/* ADD ENROLLEE MODAL */}
        <AddEditHMOEnrolleeModal
          isOpen={addEnrolleeModal}
          setIsOpen={setAddEnrolleeModal}
          type="add"
          refetchData={() =>
            refetch({
              variables: {
                providerId: providerId,
                first: 10,
              },
            })
              .then(({ data }) => {
                setCompanyEnrollees(data?.getEnrollees?.data || []);
                setPageInfo(data?.getEnrollees?.pageInfo || {});
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
              })
          }
        />

        {/* ======== CONFIRM UPLOAD ENROLLEE MODAL ========= */}
        <ConfirmModal
          open={confirmUploadModal}
          setOpen={setConfirmUploadModal}
          title="Confirm Upload Enrollee list"
          confirmationMsg="Uploading a new Enrollee JSON file will overwrite the current file if you select the replace."
          onConfirm={() => {
            setConfirmUploadModal(false);
            setUploadListModal(true);
          }}
          onCancel={() => {
            setConfirmUploadModal(false);
          }}
        />

        {/* UPLOAD LIST MODAL */}
        <MainModal isOpen={uploadListModal} setIsOpen={setUploadListModal}>
          <UploadEnrolleeListForm
            onSubmit={async (values) => {
              const throwError = () =>
                displayAlert(
                  "error",
                  "Something went wrong while uploading file."
                );
              try {
                const { file } = values;
                const fileUrl = await uploadFile(file);

                if (typeof fileUrl !== "string") {
                  throwError();
                  return;
                }

                const { data } = await uploadBulkEnrollees({
                  variables: {
                    planId: values?.planId,
                    fileUrl: fileUrl,
                    providerId: providerId,
                    replace: values?.replace,
                    companyId: values?.companyId,
                  },
                });

                if (!data) {
                  throwError();
                  return;
                }

                const enrolleesAdded =
                  data?.uploadEnrollees?.result?.totalInserted;

                displayAlert(
                  "success",
                  `${enrolleesAdded && enrolleesAdded} Enrollees Added.`
                );
                fetchData(refetch);
                setUploadListModal(false);
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error("Error from onSubmit Test JSON file", error);
                const errMsg = getErrorMsg(error);
                displayAlert("error", errMsg);
              }
            }}
          />
        </MainModal>
      </Grid>
    </Grid>
  );
};

export default CompanyEnrollees;
