import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Copy from "components/Copy";
import { Formik, Form } from "formik";
import { useAlert } from "components/hooks";
import { useMutation } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import { CustomButton } from "components/Utilities";
import { FormikControl } from "components/validation";
import { updateWidgetConfiguration } from "components/graphQL/Mutation";
import { widgetPositionOptions, widgetSizeOptions } from "helpers/mockData";
import { generateConsultationWidgetScript, hasScriptBeenGenerated } from "helpers/func";

export const createConsultationWidgetValidationSchema = Yup.object({
  providerId: Yup.string("Enter providerId")
    .trim()
    .required("Provider ID is required"),
  apiKey: Yup.string("Enter your API key")
    .trim()
    .required("API key is required"),
  widgetSize: Yup.string("Select your desired widget size")
    .trim()
    .required("Widget size is required"),
  widgetPosition: Yup.string("Select your preferred position")
    .trim()
    .required("Widget position is required"),
  widgetColor: Yup.string("Pick your preferred color")
    .trim()
    .required("Widget color is required"),
  text: Yup.string("Enter a header text").trim(),
  textColor: Yup.string("Pick your preferred text color")
    .trim()
    .required("Text color is required"),
  logomarkUrl: Yup.string("upload your preferred Logomark").trim(),
  wordmarkUrl: Yup.string("upload your preferred Wordmark")
    .trim()
    .required("Wordmark is required"),
});

const CreateConsultationWidgetForm = ({
  accountInfo,
  initialValues,
  refreshInfo,
  widgetScriptType,
}) => {
  const theme = useTheme();
  const { accountID } = accountInfo;
  const { getErrorMsg, displayMessage } = useAlert();
  const cdnBaseUrl = process.env.REACT_APP_CDN_BASE_URL;
  const [isGenerated, setIsGenerated] = useState(false);
  const [widgetScript, setWidgetScript] = useState(null);
  const [updateWidgetConfig] = useMutation(updateWidgetConfiguration);

  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
  };

  const setGeneratedScript = (values) => {
    const generatedScript = generateConsultationWidgetScript(
      values,
      cdnBaseUrl,
      widgetScriptType
    );
    setWidgetScript(generatedScript);
    setIsGenerated(true);
  };

  const onSubmit = async (values) => {
    try {
      const {
        text,
        textColor,
        widgetColor,
        logomarkUrl,
        widgetSize,
        widgetPosition,
        wordmarkUrl,
      } = values;
      const variables = {
        partnerId: accountID,
        widgetColor,
        widgetPosition,
        widgetSize,
        widgetTextHeader: text,
        widgetTextColor: textColor,
        widgetLogo: wordmarkUrl,
        widgetIcon: logomarkUrl,
        category: widgetScriptType ,
      };
      const { data } = await updateWidgetConfig({ variables });
      if (data) {
        setGeneratedScript(values);
        refreshInfo();
      }
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayMessage("error", errMsg);
      setGeneratedScript(values);
    }
  };



  useEffect(() => {
    if (hasScriptBeenGenerated(initialValues)) setGeneratedScript(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      {isGenerated ? (
        <Grid>
          {widgetScript ? (
            <>
              <Typography>
                Copy the script below and paste in your code.
              </Typography>
              <Grid
                sx={{
                  display: "flex",
                  position: "relative",
                  margin: "2rem 0rem",
                  width: {xs: "75vw", sm: "350px"},
                }}
              >
                <code
                  style={{
                    
                    backgroundColor: "rgba(191, 201, 226, 0.5)",
                    color: "rgba(62, 94, 169, 1)",
                    border: "1px solid rgba(62, 94, 169, 1)",
                    borderRadius: "5px",
                    padding: "1rem",
                    maxHeight: "200px",
                    overflowY: "scroll",
                  }}
                >
                  {widgetScript}
                </code>
                <Grid
                  sx={{ position: "absolute", top: "1rem", right: "1.5rem" }}
                >
                  <Copy text={widgetScript} name="Widget script" size="large" />
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography>
              No generated script. Please click on the button below.
            </Typography>
          )}
          <Grid item xs={12}>
            <CustomButton
              title="Regenerate"
              width="100%"
              type={buttonType}
              onClick={() => setIsGenerated(false)}
            />
          </Grid>
        </Grid>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values)}
          validationSchema={createConsultationWidgetValidationSchema}
          validateOnChange={true}
          validateOnMount={false}
          validateOnBlur={true}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form style={{ marginTop: "1rem" }}>
                <Grid item container direction="column" gap={1}>
                  <Grid item container rowSpacing={2}>
                    <Grid item container>
                      <FormikControl
                        control="select"
                        options={[
                          { key: "select widget size", value: "" },
                          ...widgetSizeOptions,
                        ]}
                        placeholder="Select Widget size"
                        name="widgetSize"
                        label="Choose Widget size"
                      />
                    </Grid>

                    <Grid item container>
                      <FormikControl
                        control="select"
                        options={[
                          { key: "Select widget position", value: "" },
                          ...widgetPositionOptions,
                        ]}
                        placeholder="Select Widget position"
                        name="widgetPosition"
                        label="Choose Widget position"
                      />
                    </Grid>

                    <Grid item container>
                      <FormikControl
                        control="input"
                        name="widgetColor"
                        label="Widget color (Color name or HEX or RGBA)"
                        placeholder="red or #000000 or rgba(255, 255, 255, 0%)"
                      />
                    </Grid>

                    <Grid item container>
                      <FormikControl
                        control="input"
                        name="text"
                        label="Text (Header)"
                        placeholder="Consult a doctor"
                      />
                    </Grid>

                    <Grid item container>
                      <FormikControl
                        control="input"
                        name="textColor"
                        label="Text color (Color name or HEX or RGBA)"
                        placeholder="red or #000000 or rgba(255, 255, 255, 0%)"
                      />
                    </Grid>

                    <Grid item container>
                      <FormikControl
                        control="file"
                        name="logomarkUrl"
                        label="Icon"
                        setFieldValue={setFieldValue}
                        hasTooltip
                        tooltip="An Icon (logomark) is an image (Logo) or symbol used to represent a company. Logomarks do not usually have the company name attached."
                        tooltipUrl="https://www.google.com/search?q=what+is+a+logomark"
                      />
                    </Grid>

                    <Grid item container>
                      <FormikControl
                        control="file"
                        name="wordmarkUrl"
                        label="Full logo"
                        setFieldValue={setFieldValue}
                        hasTooltip
                        tooltip="A Full logo (wordmark) is usually a distinct text-only typographic treatment of the name of a company, institution, or product name used for purposes of identification and branding. Wordmark have the company name attached. E.g FedEx and Google."
                        tooltipUrl="https://www.google.com/search?q=what+is+a+workmark"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CustomButton
                        title="Generate"
                        type={buttonType}
                        width="100%"
                        isSubmitting={isSubmitting}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Grid>
  );
};

CreateConsultationWidgetForm.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  refreshInfo: PropTypes.func,
  widgetScriptType: PropTypes.oneOf(["hospital", "hmo"]).isRequired,
};

export default CreateConsultationWidgetForm;
