import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Checkbox,
  ClickAwayListener,
  Grid,
  Paper,
  TableCell,
  TableRow,
} from "@mui/material";

import { useAlert } from "components/hooks";
import EditIcon from "components/Icons/EditIcon";
import { defaultPageInfo } from "helpers/mockData";
import DeleteIcon from "components/Icons/deleteIcon";
import { useStyles } from "styles/enrolleesPageStyles";
import StatusPill from "components/Utilities/StatusPill";
import { useActions } from "components/hooks/useActions";
import TableLayout from "components/layouts/TableLayout";
import SearchContainer from "components/Utilities/Searchs";
import { CustomButton, Loader } from "components/Utilities";
import { EmptyTable, EnhancedTable } from "components/layouts";
import { getEnrolleeProfileId } from "components/graphQL/useQuery";
import { formatDate, onhandleSingleSelectClick } from "helpers/func";
import EnrolleeFilters from "components/Forms/Filters/EnrolleeFilters";
import AddEditEnrolleeModal from "components/modals/AddEditEnrolleeModal";
import EnrolleeProfileModal from "components/modals/EnrolleeProfileModal";
import { useMutation, NetworkStatus, useLazyQuery } from "@apollo/client";
import { enrollesTableHeadCells } from "components/Utilities/tableHeaders";
import ConfirmDeleteOrDisable from "components/modals/ConfirmDeleteOrDisable";
import {
  deactivateEnrollee,
  deleteEnrollee,
} from "components/graphQL/Mutation";
import {
  changeHospitalTableLimit,
  handleHmoPageChange,
} from "helpers/filterHelperFunctions";

let deleteCount = 0;

const EnrolleesTable = ({ enrolleesParams, showHeader }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { companyId } = useParams();
  const [open, setOpen] = useState({});
  const { setSelectedRows } = useActions();
  const [editData, setEditData] = useState(null);
  const { displayAlert, getErrorMsg } = useAlert();
  const [isDeleting, setIsDeleting] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [profileDetails, setProfileDetails] = useState({});
  const isSelected = (id) => selectedRows.indexOf(id) !== -1;
  const [isBulkDeleting, setIsBulkDeleting] = useState(false);
  const providerId = localStorage.getItem("partnerProviderId");
  const { selectedRows } = useSelector((state) => state.tables);
  const [editEnrolleeModal, setEditEnrolleeModal] = useState(false);
  const [profileDetailModal, setProfileDetailModal] = useState(false);
  const [enrolleeToDelete, setEnrolleeToDelete] = React.useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = React.useState(false);
  const [removeEnrollee] = useMutation(deleteEnrollee);
  const [switchActivation, { loading: loadingSwitchActive }] =
    useMutation(deactivateEnrollee);
  const [getProfileId, { loading: loadingEnrollee }] = useLazyQuery(
    getEnrolleeProfileId,
    {
      variables: { providerId: providerId },
    }
  );

  const {
    enrollees,
    pageInfo,
    fetchEnrollees,
    loading,
    networkStatus,
    setEnrollees,
    setPageInfo,
    refetch,
    variables,
  } = enrolleesParams;

  const deleteBtn = {
    background: "#A93E3E",
    hover: theme.palette.common.danger,
    color: "#ffffff",
  };

  React.useEffect(() => {
    return () => setSelectedRows([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  React.useEffect(() => {
    const isDeletingObj = {};
    const isOpen = {};
    (enrollees || []).map((enrollee) => {
      isDeletingObj[enrollee?._id] = false;
      isOpen[enrollee?._id] = false;
      return null;
    });
    setIsDeleting(isDeletingObj);
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollees]);

  const refreshData = () => {
    refetch({
      variables: {
        providerId: providerId,
        first: 10,
      },
    })
      .then(({ data }) => {
        setEnrollees(data?.getEnrollees?.data || []);
        setPageInfo(data?.getEnrollees?.pageInfo || {});
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const setTableData = async (response, errMsg) => {
    response
      .then(({ data }) => {
        setEnrollees(data?.getEnrollees?.data || []);
        setPageInfo(data?.getEnrollees?.pageInfo || defaultPageInfo);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        displayAlert("error", errMsg);
      });
  };

  const handleClickAway = (enrolleeId) => {
    setOpen((prevObj) => ({ ...prevObj, [enrolleeId]: false }));
  };

  const activateOrDeactivateEnrollee = (enrolleeId, enrolleeName) => {
    switchActivation({ variables: { enrolleeId } })
      .then((res) => {
        handleClickAway(enrolleeId);
        const status = res?.data?.deactivateEnrollee?.enrollee?.deactivated
          ? "deactivated"
          : "activated";
        displayAlert("success", `${enrolleeName} ${status} successfully`);
        refreshData();
      })
      .catch((error) => {
        console.error(error);
        const errMsg = getErrorMsg(error);
        displayAlert("error", errMsg);
      });
  };

  const delEnrollee = async (id) => {
    const { data } = await removeEnrollee({
      variables: {
        id,
      },
    });

    return data;
  };

  const bulkDeleteEnrollees = async (ids = []) => {
    try {
      setIsBulkDeleting(true);
      const idPromises = ids.map(async (id) => {
        const data = await delEnrollee(id);
        if (!data) return false;
        if (data) {
          deleteCount++;
          return true;
        }
      });

      await Promise.allSettled(idPromises);

      if (ids.length === deleteCount)
        displayAlert("success", `${deleteCount} Enrollees deleted successfully.`);
      if (ids.length > deleteCount) {
        displayAlert(
          "success",
          `${deleteCount} Enrollees deleted successfully.`
        );
        displayAlert(
          "error",
          `Failed to delete ${ids.length - deleteCount} Enrollee(s)`
        );
      }
      deleteCount = 0;
      setSelectedRows([]);
      refreshData();
      setIsBulkDeleting(false);
    } catch (error) {
      refreshData();
      setIsBulkDeleting(false);
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
  };

  // REFACTOR FILTER AND SEARCH
  // MAKE SEARCH AND FILTER USE ONE VARIABLES SOURCE
  return (
    <Grid>
      <TableLayout
        showHeader={showHeader}
        header="Enrollees"
        filters={
          <EnrolleeFilters
            setEnrollees={setEnrollees}
            setPageInfo={setPageInfo}
            queryParams={{
              variables,
              fetchEnrollees,
              refetch,
              newVariables: { companyId },
            }}
          />
        }
        actions={
          selectedRows.length > 0 ? (
            <Grid sx={{ marginRight: "1rem" }}>
              <CustomButton
                startIcon={<DeleteIcon />}
                title="Delete"
                type={deleteBtn}
                isSubmitting={isBulkDeleting}
                onClick={() => bulkDeleteEnrollees(selectedRows)}
              />
            </Grid>
          ) : (
            <div></div>
          )
        }
        search={
          <SearchContainer
            onChange={(e) => {
              const value = e?.target?.value;
              setSearchValue(value);
            }}
            placeholder="By Name or HMO plan"
            onClickSearchBtn={() => {
              fetchEnrollees({ variables: { search: searchValue, companyId } })
                .then((res) => {
                  setEnrollees(res?.data?.getEnrollees?.data || []);
                  setPageInfo(res?.data?.getEnrollees?.pageInfo || {});
                })
                .catch((error) => {
                  const errorMsg = getErrorMsg(error);
                  displayAlert("error", errorMsg);
                });
            }}
          />
        }
      >
        {loading ? (
          <Loader />
        ) : networkStatus === NetworkStatus.refetch ? (
          <Loader />
        ) : isBulkDeleting ? (
          <Loader />
        ) : enrollees?.length > 0 ? (
          /* ================= ENROLLEES TABLE ================= */
          <Grid
            container
            item
            direction="column"
            overflow="hidden"
            maxWidth={{ sm: "calc(100vw - 48px)", lg: "100%", }}
          >
            <EnhancedTable
              headCells={enrollesTableHeadCells}
              rows={enrollees}
              paginationLabel="Enrollees per page"
              hasCheckbox={true}
              dataPageInfo={pageInfo}
              selectedRows={selectedRows}
              // onSelectAllClick={(e) => handleSelectAllClick(e)}
              changeLimit={async (e) => {
                const res = changeHospitalTableLimit(fetchEnrollees, {
                  first: e,
                });
                await setTableData(res, "Failed to change table limit.");
              }}
              handlePagination={async (page) => {
                const res = handleHmoPageChange(
                  fetchEnrollees,
                  page,
                  pageInfo,
                  {}
                );
                await setTableData(res, "Failed to change page.");
              }}
            >
              {enrollees.map((row, index) => {
                const {
                  _id,
                  hmoId,
                  firstName,
                  lastName,
                  noc,
                  plan,
                  expiryDate,
                  status,
                  planId,
                  email,
                  phone,
                  companyId,
                  companyName,
                  deactivated,
                } = row;

                const editEnrolleeData = {
                  id: _id,
                  hmoId: hmoId || "",
                  firstName: firstName || "",
                  lastName: lastName || "",
                  noc: noc || "",
                  plan: plan || "",
                  planId: planId || "",
                  expiryDate: expiryDate || "",
                  email: email || "",
                  phone: phone || "",
                  companyId: companyId || "",
                  companyName: companyName || "",
                };
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    key={_id}
                    hover
                    role="checkbox"
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      overflow: "unset",
                    }}
                    tabIndex={-1}
                    onClick={() => {
                      setProfileDetailModal(true);
                      setProfileDetails(row);
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          onhandleSingleSelectClick(_id, selectedRows, setSelectedRows);
                        }}
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey,
                        textAlign: "left",
                      }}
                    >
                      {hmoId}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {`${firstName && firstName} ${lastName && lastName}`}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {`${companyName ? companyName : "No company"}`}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {noc ? noc : 0}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {plan ? plan : "No Plan"}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {expiryDate ? formatDate(expiryDate, "P") : "Nil"}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <StatusPill
                      width="110px"
                        type={deactivated ? "error" : status === true ? "success" : ""}
                        label={deactivated ? "Deactivated" : status === true ? "ACTIVE" : "INACTIVE"}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ position: "relative" }}
                      className={classes.tableCell}
                    >
                      <Grid container>
                        <ClickAwayListener
                          onClickAway={(e) => {
                            e.stopPropagation();
                            handleClickAway(_id);
                          }}
                        >
                          {isDeleting[_id] ? (
                            <Loader />
                          ) : (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpen((prev) => ({
                                  ...prev,
                                  [_id]: true,
                                }));
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                            >
                              <MoreHorizIcon fontSize="large" />
                            </button>
                          )}
                        </ClickAwayListener>
                      </Grid>
                    </TableCell>
                    <TableCell sx={{ position: "absolute", borderBottom: "none"}}>
                    {open[_id] ? (
                      <Paper className={classes.options} elavation={4}>
                        <button
                          className={`${classes.btn} ${classes.editBtn}`}
                          style={{ borderRadius: "8px 8px 0px 0px" }}
                          disabled={!status}
                          onClick={async (e) => {
                            e.stopPropagation();
                            getProfileId({ variables: { hmoId } })
                              .then(({ data }) => {
                                if (
                                  data &&
                                  data?.profiles?.data?.length !== 0
                                ) {
                                  const profileId =
                                    data?.profiles?.data[0]?._id;
                                  history.push(`/patients/${profileId}`);
                                } else {
                                  displayAlert(
                                    "error",
                                    "Patients profile not found."
                                  );
                                }
                              })
                              .catch((error) => {
                                // eslint-disable-next-line no-console
                                console.error(
                                  "couldn't get enrollee profile ID",
                                  error
                                );
                                const errorMsg = getErrorMsg(error);
                                displayAlert("error", errorMsg);
                              });
                          }}
                        >
                          {loadingEnrollee ? <Loader /> : "View profile"}
                        </button>
                        <button
                          className={`${classes.btn} ${classes.editBtn}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditData(editEnrolleeData);
                            setEditEnrolleeModal(true);
                          }}
                        >
                          Edit <EditIcon sx={{ marginLeft: "0.7rem" }} />
                        </button>
                        <button
                          className={`${classes.btn} ${
                            deactivated ? classes.editBtn : classes.delBtn
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            activateOrDeactivateEnrollee(
                              _id,
                              `${firstName} ${lastName}`
                            );
                          }}
                        >
                          {loadingSwitchActive ? (
                            <Loader />
                          ) : deactivated ? (
                            "Activate"
                          ) : (
                            "Deactivate"
                          )}
                        </button>
                        <button
                          className={`${classes.btn} ${classes.delBtn}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setEnrolleeToDelete(_id);
                            setConfirmDeleteModal(true);
                          }}
                        >
                          Delete <DeleteIcon sx={{ marginLeft: "0.7rem" }} />
                        </button>
                      </Paper>
                    ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </EnhancedTable>
          </Grid>
        ) : (
          <EmptyTable
            headCells={enrollesTableHeadCells}
            paginationLabel="Enrollees per page"
          />
        )}
      </TableLayout>

      {/* ==== PROFILE DETAILS MODAL ===== */}
      <EnrolleeProfileModal
        isOpen={profileDetailModal}
        setIsOpen={setProfileDetailModal}
        profileData={profileDetails}
      />

      {/*  ======= CONFIRM DELETE ======== */}
      <ConfirmDeleteOrDisable
        open={confirmDeleteModal}
        setOpen={setConfirmDeleteModal}
        title="Delete Enrollee"
        btnValue="delete"
        onConfirm={async () => {
          setConfirmDeleteModal(false);
          const newIsDeleting = isDeleting;

          try {
            newIsDeleting[enrolleeToDelete] = true;
            setIsDeleting({ ...newIsDeleting });
            const data = await delEnrollee(enrolleeToDelete);
            if (!data) {
              newIsDeleting[enrolleeToDelete] = false;
              setIsDeleting({ ...newIsDeleting });
              throw Error("Couldn't delete enrollee");
            }
            displayAlert("success", "Deleted enrollee successfully.");
            refreshData();
            newIsDeleting[enrolleeToDelete] = false;
            setIsDeleting({ ...newIsDeleting });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            const errMsg = getErrorMsg(error);
            displayAlert("error", errMsg);
            newIsDeleting[enrolleeToDelete] = false;
            setIsDeleting({ ...newIsDeleting });
          }
        }}
        confirmationMsg="Are you sure you want to delete"
        onCancel={() => {
          setConfirmDeleteModal(false);
        }}
      />

      {/* EDIT ENROLLEE MODAL */}
      <AddEditEnrolleeModal
        isOpen={editEnrolleeModal}
        setIsOpen={setEditEnrolleeModal}
        type="edit"
        initialValues={editData}
        refetchData={() => refreshData()}
      />
    </Grid>
  );
};

export default EnrolleesTable;
