import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Grid, TextField } from "@mui/material";

import Filter from ".";
import { useAlert } from "components/hooks";
import { useLazyQuery, useQuery } from "@apollo/client";
import { getCompanies, getPlans } from "components/graphQL/useQuery";
import { deleteVar, filterHmoData } from "helpers/filterHelperFunctions";
import { AutoComplete } from "components/validation/AutoComplete";

const EnrolleeFilters = ({ setEnrollees, setPageInfo, queryParams }) => {
  const [companies, setCompanies] = useState([]);
  const { displayAlert, getErrorMsg } = useAlert();
  const [planOptions, setPlanOptions] = useState([]);
  const { variables, fetchEnrollees, refetch, newVariables } = queryParams;
  const providerId = localStorage.getItem("partnerProviderId");
  const { data: plansData } = useQuery(getPlans, {
    variables: { type: "hmo" },
  });
  const [fetchCompanies, { loading: loadingCompanies }] = useLazyQuery(
    getCompanies,
    { variables: { page: -1 }, fetchPolicy: "no-cache" }
  );
  const [enrolleeFilterValue, setEnrolleeFilterValue] = useState({
    planId: "",
  });

  const onFilterChange = async (name, value) => {
    try {
      deleteVar(variables);
      const filterValues = {
        ...newVariables,
        ...enrolleeFilterValue,
        [name]: value,
        providerId,
      };
      setEnrolleeFilterValue(filterValues);
      filterHmoData(filterValues, {
        fetchData: fetchEnrollees,
        refetch,
        variables,
      })
        .then((data) => {
          setEnrollees(data?.getEnrollees?.data || []);
          setPageInfo(data?.getEnrollees?.pageInfo || {});
        })
        .catch((error) => {
          console.error(error);
          const errMsg = getErrorMsg(error);
          displayAlert("error", errMsg);
          refresh(setEnrolleeFilterValue, { plan: "" });
        });
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
      refresh(setEnrolleeFilterValue, { plan: "" });
    }
  };

  const refresh = async (setFilterValue, defaultVal) => {
    displayAlert("error", "Something went wrong while filtering. Try again.");
    setFilterValue(defaultVal);
    deleteVar(variables);

    refetch({ variables: { providerId, ...newVariables } })
      .then(({ data }) => {
        setEnrollees(data?.getEnrollees?.data || []);
        setPageInfo(data?.getEnrollees?.pageInfo || {});
      })
      .catch((error) => {
        console.error(error);
        displayAlert("error", "Failed to get HMO enrollees data, Try again");
      });
  };

  const setCompaniesOptions = (companyOptions) => {
    const data = companyOptions || [];
      const options = data.map(
      (company) => {
        return { key: company?.name, value: company?._id };
      }
    );
    setCompanies(options);
  }

  const handleCompanySearchChange = (company) => {
    fetchCompanies({variables: {search: company, providerId}})
    .then((res)=>{
      const companyOptions = res?.data?.getCompanies?.data;
      if (!companyOptions || companyOptions?.length === 0) return;
      setCompaniesOptions(companyOptions);
    })
    .catch((error)=>{
      console.error(error);
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchCompanies = useCallback(debounce(handleCompanySearchChange, 1000), []);

  useEffect(() => {
    const options = (plansData?.getPlans?.plan || []).map((option) => {
      return { key: option?.name, value: option?._id };
    });
    setPlanOptions(options);
  }, [plansData]);

  return (
    <Grid item container flexWrap="wrap" spacing={2} alignItems="flex-end">
      {/* FILTER BY PLAN */}
      <Grid item>
        <Filter
          onHandleChange={(e) => onFilterChange("planId", e?.target?.value)}
          onClickClearBtn={() => onFilterChange("planId", "")}
          options={[{ key: "Access Type", value: "" }, ...planOptions]}
          name="planId"
          value={enrolleeFilterValue.planId}
          hasClearBtn={true}
          disable={true}
          variant="medium"
        />
      </Grid>

      {/* FILTER BY COMPANY */}
      {!newVariables?.companyId && (
        <Grid item>
        <AutoComplete
          id="companyId"
          height="55px"
          name="companyId"
          loading={loadingCompanies}
          sx={{ minWidth: "200px" }}
          onInputChange={(e) => {
            const value = e?.target?.value;
            if(!value || value === "") return;
            searchCompanies(value);
          }}
          onChange={(e, info) => {
            const value = info?.value;
            onFilterChange("companyId", value);
          }}
          options={companies}
          clearOnBlur={false}
          noOptionsText="Type to see options"
          getOptionLabel={(option) => option.key}
          renderOption={(props, option) => <li {...props}>{option?.key}</li>}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Company" />
          )}
        />
      </Grid>
      )}
    </Grid>
  );
};

export default EnrolleeFilters;
