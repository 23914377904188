import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Loader } from "components/Utilities";
import { useTheme } from "@mui/material/styles";
import HospitalProfile from "./HospitalProfile";
import SwipeableViews from "react-swipeable-views";
import { useLazyQuery, useQuery } from "@apollo/client";
import GenerateAPIKeyForm from "components/Forms/GenerateAPIKeyFrom";
import { AppBar, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import {
  getAccountPrivateInfo,
  getHMOWidgetConfiguration,
  getPartnerID,
} from "components/graphQL/useQuery";
import CreateWebappLinkForm from "components/Forms/WebappSettingsForm";
import CreateConsultationWidgetForm from "components/Forms/CreateConsultationWidgetForm";
import { SettingsFormContainer } from "components/layouts/SettingsContainer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const IntegratationSubMenu = ({ accountInfo }) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { apiKey, providerId } = accountInfo;
  const [initialValues, setInitialValues] = useState(null);
  const [webappSettingsInitialValues, setWebappSettingsInitialValues] =
    useState(null);
  const { data, loading, refetch } = useQuery(getHMOWidgetConfiguration, {
    variables: { partnerId: accountInfo?.accountID },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    if (data) {
      const {
        widgetColor,
        widgetPosition,
        widgetSize,
        widgetTextHeader,
        widgetTextColor,
        widgetLogo,
        widgetIcon,
        subdomain,
        category,
      } = data?.getPartnerConfiguration;

      const initialValues = {
        providerId: providerId || "",
        apiKey: apiKey || "",
        widgetSize: widgetSize || "",
        widgetPosition: widgetPosition || "",
        widgetColor: widgetColor || "",
        text: widgetTextHeader || "",
        textColor: widgetTextColor || "",
        logomarkUrl: widgetIcon || "",
        wordmarkUrl: widgetLogo || "",
        category: category || "",
      };
      setInitialValues(initialValues);
      setWebappSettingsInitialValues({
        providerId: providerId || "",
        brandName: subdomain || "",
        brandColor: widgetColor || "",
        brandLogo: widgetLogo || "",
      });
    }
  }, [apiKey, data, providerId]);

  return (
    <>
      <Grid container justifyContent="center">
        <Typography>Choose a platform to integrate</Typography>
        <Box
          sx={{
            bgcolor: "background.paper",
            width: { xs: "100%", sm: 400 },
            margin: "2rem 0rem",
            borderRadius: "5px",
          }}
        >
          <AppBar position="static" sx={{ borderRadius: "5px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="JS Widget" {...a11yProps(0)} />
              <Tab label="Web link" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {loading ? (
                <Loader />
              ) : (
                initialValues && (
                  <CreateConsultationWidgetForm
                    accountInfo={accountInfo}
                    initialValues={initialValues}
                    refreshInfo={refetch}
                    widgetScriptType="hospital"
                  />
                )
              )}
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              {loading ? (
                <Loader />
              ) : (
                initialValues && (
                  <CreateWebappLinkForm
                    accountInfo={accountInfo}
                    initialValues={webappSettingsInitialValues}
                    refreshInfo={refetch}
                    widgetScriptType="hospital"
                  />
                )
              )}
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Grid>
    </>
  );
};

IntegratationSubMenu.propTypes = {
  accountInfo: PropTypes.object.isRequired,
};

const HospitalSettings = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [accountID, setAccountID] = useState(null);
  const { _id } = JSON.parse(localStorage.getItem("partnerData"));
  const [fetchPartnerID, { loading, error }] = useLazyQuery(getPartnerID);
  const [accountInfo, setAccountInfo] = useState({
    apiKey: null,
    providerId: "",
  });

  const [fetchAccInfo, { loading: loadingAcc, error: accError }] = useLazyQuery(
    getAccountPrivateInfo
  );

  React.useEffect(() => {
    ["account_id", "email", "heala_id", "profile_id", "token"].map((text) =>
      localStorage.setItem(text, "")
    );
  }, []);

  React.useEffect(() => {
    fetchPartnerID({ variables: { id: _id } })
      .then(({ data }) => {
        const accountId = data?.getPartner?.accountId;
        setAccountID(accountId);
        return fetchAccInfo({ variables: { id: accountId } });
      })
      .then((res) => {
        const { apiKey, providerId } = res?.data?.account;
        setAccountInfo({ apiKey, providerId });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [_id, fetchAccInfo, fetchPartnerID]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Box
          sx={{
            bgcolor: "background.paper",
            width: 600,
            margin: "2rem 0rem",
            borderRadius: "5px",
          }}
        >
          <AppBar position="static" sx={{ borderRadius: "5px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              aria-label="full width tabs example"
            >
              <Tab label="PROFILE" {...a11yProps(0)} />
              <Tab label="API KEYS & CONSTANTS" {...a11yProps(1)} />
              <Tab label="INTEGRATION" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <SettingsFormContainer>
                <HospitalProfile />
              </SettingsFormContainer>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <SettingsFormContainer>
                {loading || loadingAcc ? (
                  <Loader />
                ) : error || accError ? (
                  <Typography>Error!!! Something went wrong.</Typography>
                ) : (
                  <GenerateAPIKeyForm
                    accountID={accountID}
                    accountInfo={accountInfo}
                    setAccountInfo={setAccountInfo}
                  />
                )}
              </SettingsFormContainer>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <SettingsFormContainer>
                <IntegratationSubMenu
                  accountInfo={{ ...accountInfo, accountID }}
                />
              </SettingsFormContainer>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Grid>
    </>
  );
};

export default HospitalSettings;
