import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import t from "prop-types";
import { debounce } from "lodash";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";

import { useAlert } from "components/hooks";
import { CustomButton } from "components/Utilities";
import { differenceInCalendarYears } from "date-fns";
import { FormikControl } from "components/validation";
import { addHMOEnrolleInitialValues } from "helpers/mockData";
import HmoDatePicker from "components/validation/HmoDatePicker";
import { formatDate, removeEmptyStringValues } from "helpers/func";
import { getCompanies, getPlans } from "components/graphQL/useQuery";
import { createEnrollee, updateEnrollee } from "components/graphQL/Mutation";

const addHMOEnrolleeValidationSchema = Yup.object({
  id: Yup.string("enter id").trim(),
  firstName: Yup.string("Enter first name")
    .trim()
    .required("First name is required"),
  lastName: Yup.string("Enter last name")
    .trim()
    .required("Last name is required"),
  email: Yup.string("Enter email address")
    .email("Enter a valid email")
    .trim()
    .required("Email is required"),
  phone: Yup.number("Enter your Phone Number")
    .typeError(" Enter a valid phone number")
    .min(11, "min value is  11 digits")
    .required("Phone number is required"),
  hmoId: Yup.string("Enter HMO ID").trim().required("HMO ID is required"),
  companyId: Yup.string("Select Company enrollee is affiliated to.").trim(),
  noc: Yup.string("Enter NOC").trim(),
  plan: Yup.string("Enter HMO plan").trim().required("HMO Plan is required"),
  planId: Yup.string("Select Heala plan")
    .trim()
    .required("Heala Plan is required"),
  expiryDate: Yup.date("Enter HMO plan expiry date")
    .typeError("Please select a valid date")
    .required("Expiry date is Required"),
});

const AddEditHMOEnrolleeForm = ({ type, editInitialValues, onSuccess }) => {
  const theme = useTheme();
  const { companyId } = useParams();
  const [date, setDate] = useState("");
  const [fetchPlans] = useLazyQuery(getPlans);
  const [expired, setExpired] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [noExpiry, setNoExpiry] = useState(false);
  const { displayAlert, getErrorMsg } = useAlert();
  const [planOptions, setPlanOptions] = useState([]);
  const [addHMOEnrollee] = useMutation(createEnrollee);
  const [updateHMOEnrollee] = useMutation(updateEnrollee);
  const providerId = localStorage.getItem("partnerProviderId");
  const formInitialValues =
    type === "edit" ? editInitialValues : addHMOEnrolleInitialValues;
  const defaultCompany =
    formInitialValues?.companyId && formInitialValues?.companyId !== ""
      ? { key: formInitialValues?.companyName, value: formInitialValues?.companyId }
      : { key: "", value: "" };

  const [fetchCompanies, { loading: loadingCompanies }] = useLazyQuery(
    getCompanies,
    { variables: { providerId, page: -1 }, fetchPolicy: "no-cache" }
  );

  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
  };

  useEffect(() => {
    fetchPlans({
      variables: { type: "hmo" },
    })
      .then(({ data }) => {
        const options = (data?.getPlans?.plan || []).map((option) => {
          return { key: option?.name, value: option?._id };
        });
        setPlanOptions(options);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [fetchPlans]);

  useEffect(() => {
    setDate(Date.now());
  }, []);

  useEffect(() => {
    const dateNow = Date.now();
    let diff = 0;
    if (type === "edit") {
      diff = differenceInCalendarYears(
        new Date(Date.parse(`${formInitialValues?.expiryDate}`)),
        dateNow
      );
    }

    if (diff > 10) {
      setNoExpiry(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddSubmit = async (values) => {
    try {
      const formatedDate = formatDate(values.expiryDate, "P");
      const variables = removeEmptyStringValues(values);
      if (!variables?.companyId) {
        variables["companyId"] = companyId;
      }
      if (!variables.providerId) {
        throw Error("Provider ID not found!");
      }
      const { data } = await addHMOEnrollee({
        variables: { expiryDate: formatedDate, ...variables },
      });
      if (data) {
        displayAlert("success", "Enrollee added successfully");
        onSuccess();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
  };

  const onUpdateSubmit = async (values) => {
    try {
      const formatedDate = formatDate(values.expiryDate, "P");
      const {companyName, ...remainingVar} = removeEmptyStringValues(values);
      const variables = remainingVar;
      // console.log(variables);
      if (!variables?.providerId && !variables?.id) {
        throw Error("Provider ID or Enrollee ID found!");
      }
      const { data } = await updateHMOEnrollee({
        variables: { expiryDate: formatedDate, ...variables },
      });
      if (data) {
        displayAlert("success", "Enrollee updated successfully");
        onSuccess();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
  };

  const setCompaniesOptions = (companyOptions) => {
    const data = companyOptions || [];
    const options = data.map((company) => {
      return { key: company?.name, value: company?._id };
    });
    setCompanies(options);
  };

  const handleCompanySearchChange = (company) => {
    fetchCompanies({ variables: { search: company } })
      .then((res) => {
        const companyOptions = res?.data?.getCompanies?.data;
        if (!companyOptions || companyOptions?.length === 0) return;
        setCompaniesOptions(companyOptions);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchCompanies = useCallback(
    debounce(handleCompanySearchChange, 1000),
    []
  );
  const disable = type === "edit";

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={(values) =>
        type === "edit"
          ? onUpdateSubmit({
              id: editInitialValues._id,
              providerId: providerId,
              ...values,
            })
          : onAddSubmit({ providerId: providerId, ...values })
      }
      validationSchema={addHMOEnrolleeValidationSchema}
      validateOnChange={true}
      validateOnMount={false}
      validateOnBlur={true}
    >
      {({ isSubmitting, setFieldValue, setValues, initialValues }) => {
        return (
          <Form style={{ marginTop: "1rem" }}>
            <Grid container direction="column" gap={2}>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="First Name"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter first name"
                  disabled={disable}
                />
              </Grid>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="Last Name"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter last name"
                  disabled={disable}
                />
              </Grid>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="HMO ID"
                  id="hmoID"
                  name="hmoId"
                  placeholder="Enter HMO ID"
                  disabled={disable}
                />
              </Grid>

              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="Email"
                  id="email"
                  name="email"
                  placeholder="Enter email address"
                  disabled={disable}
                />
              </Grid>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="Phone number"
                  id="phone_number"
                  name="phone"
                  placeholder="Enter phone number"
                />
              </Grid>
              {!companyId && (
                <Grid item container direction="column" gap={1}>
                  <FormLabel
                    sx={{ color: "#979797", fontWeight: 400 }}
                    component="legend"
                  >
                    Company
                  </FormLabel>
                  {/* make a fake auto complete to house existing company */}
                  <Autocomplete
                    id="companyId"
                    name="companyId"
                    loading={loadingCompanies}
                    sx={{ minWidth: "200px" }}
                    defaultValue={defaultCompany}
                    onInputChange={(e) => {
                      const value = e?.target?.value;
                      if (!value || value === "") return;
                      searchCompanies(value);
                    }}
                    onChange={(e, info) => {
                      const value = info?.value;
                      setFieldValue("companyId", value);
                    }}
                    options={companies}
                    clearOnBlur={false}
                    noOptionsText="Type to see options"
                    getOptionLabel={(option) => option.key}
                    renderOption={(props, option) => (
                      <li {...props}>{option?.key}</li>
                    )}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select company affiliated to enrollee"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="HMO Plan"
                  id="plan"
                  name="plan"
                  placeholder="Enter HMO Plan"
                />
              </Grid>
              <Grid item container direction="column" gap={1}>
                {noExpiry === true ? (
                  ""
                ) : expired === true ? (
                  ""
                ) : (
                  <HmoDatePicker
                    label="Expiry date (for HMO plan)"
                    id="expiry"
                    name="expiryDate"
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    startDate={date}
                  />
                )}

                <Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="expire"
                        checked={noExpiry}
                        onChange={() => {
                          const newChecked = !noExpiry;
                          setNoExpiry(newChecked);
                          setExpired(false);
                          if (newChecked) {
                            const date = new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() + 100
                              )
                            );
                            setFieldValue("expiryDate", date);
                          } else {
                            setFieldValue(
                              "expiryDate",
                              initialValues?.expiryDate
                            );
                          }
                        }}
                      />
                    }
                    label="No expiry date"
                  />
                  {type === "edit" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="expire"
                          checked={expired}
                          onChange={() => {
                            const newChecked = !expired;
                            setExpired(newChecked);
                            setNoExpiry(false);
                            if (newChecked) {
                              const date = new Date(
                                new Date().setDate(new Date().getDate() - 1)
                              );
                              setFieldValue("expiryDate", date);
                            } else {
                              setFieldValue(
                                "expiryDate",
                                initialValues?.expiryDate
                              );
                            }
                          }}
                        />
                      }
                      label="Expired"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="select"
                  options={[{ key: "Plan", value: "" }, ...planOptions]}
                  placeholder="Select Plan"
                  name="planId"
                  label="Heala Access Plan"
                />
              </Grid>

              <Grid item container>
                <CustomButton
                  title={type === "edit" ? "Update Enrollee" : "Add Enrollee"}
                  width="100%"
                  type={buttonType}
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

AddEditHMOEnrolleeForm.propTypes = {
  onSuccess: t.func.isRequired,
  editInitialValues: t.object,
  type: t.string.isRequired,
};

export default AddEditHMOEnrolleeForm;
