import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Copy from "components/Copy";
import { makeStyles } from "@mui/styles";
import { formatSecs } from "helpers/func";
import { NoData } from "components/layouts";
import { Loader } from "components/Utilities";
import { useLazyQuery, useQuery } from "@apollo/client";
import StatusPill from "components/Utilities/StatusPill";
import { useParams, useHistory } from "react-router-dom";
import { EditDelBtn } from "components/Buttons/EditDelBtn";
import { TestRefModal } from "components/modals/TestRefModal";
import { trucateString } from "helpers/filterHelperFunctions";
import { Grid, Typography, Avatar, Rating } from "@mui/material";
import { dateMoment, timeMoment } from "components/Utilities/Time";
import { getConsult, getRefferal } from "components/graphQL/useQuery";
import { PrescriptionModal } from "components/modals/PrescriptionModal";

const useStyles = makeStyles((theme) => ({
  parentGridWrapper: {
    background: "#fff",
    borderRadius: "1rem",
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "4rem",

    "&:not(:last-of-type)": {
      marginBottom: "5rem",
    },
  },

  infoBadge: {
    "&.MuiChip-root": {
      fontSize: "1.5rem",
      borderRadius: "1.5rem",
    },
  },

  title: {
    "&.MuiTypography-root": {
      color: theme.palette.common.grey,
      fontWeight: 600,
      fontSize: "0.9em",
      whitespace: "wrap",
    },
  },

  info: {
    "&.MuiTypography-root": {
      color: theme.palette.common.grey,
      fontWeight: 400,
      whitespace: "wrap",
    },
  },
  item: {
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "nowrap",
    // justifyContent: "space-between",
    "&.MuiGrid-root > *": {
      flex: 1,
    },
  },
  subItem: {
    "&.MuiGrid-container": {
      flexDirection: "column",
      wordBreak: "break-word",
    },
  },

  underline: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const CaseNoteContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      flexWrap="wrap"
      display="grid"
      gridTemplateColumns={{
        lg: "1fr 1fr 1fr",
        md: "1fr 1fr",
        sm: "1fr",
      }}
      className={classes.item}
      alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
      rowGap={{ xs: "1.5rem", sm: "1.5rem", lg: "3rem" }}
      columnGap={{ xs: "1.5rem", sm: "1.5rem" }}
    >
      {children}
    </Grid>
  );
};

CaseNoteContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const CaseNotes = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const partnerData = JSON.parse(localStorage.getItem("partnerData"));
  const category = partnerData?.category;

  const [testRefOpen, setTestRefOpen] = useState(false);
  const [fetchTestRef, { data: testRefData, loading: loadingTestRef }] =
    useLazyQuery(getRefferal);

  const { loading, data, error } = useQuery(getConsult, {
    variables: {
      id,
    },
  });

  const {
    _id,
    prescription,
    type,
    consultationDuration,
    patientData,
    referralId,
    createdAt,
    discomfortLevel,
    status,
    description,
    // updatedAt,
    doctorData,
    doctorNote,
    firstNotice,
    contactMedium,
    symptoms,
    isDisputed,
    disputeReason,
    disputeStatus,
    appointmentAcceptedAt,
    declineReason,
    appointmentStartedAt,
    patientJoined,
    patientSatisfied,
    patientEndCommunicationReason,
    reason,
    rating,
    doctorJoined,
    doctorEndCommunicationReason,
    diagnosis,
    disputeResolvedReason,
  } = data?.getConsultation || {};

  const isNotDeputed = useMemo(
    () => !isDisputed && disputeStatus === "pending",
    [disputeStatus, isDisputed]
  );

  useEffect(() => {
    if (data && referralId) {
      // fetch test ref
      fetchTestRef({ variables: { id: referralId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const testRefArr = useMemo(() => {
    return testRefData?.getReferral?.tests;
  }, [testRefData]);

  if (loading) return <Loader />;
  if (error) return <NoData error={error} />;

  return (
    <>
      <Grid container direction="column" gap={1} sx={{ margin: "3rem 0rem" }}>
        {/* ===== CONSULTATION GENERAL INFORMATION ===== */}
        <Grid
          item
          container
          direction="column"
          className={classes.parentGridWrapper}
        >
          <Grid container alignItems="center" marginBottom="3rem" gap={2}>
            <Typography
              variant="h3"
              sx={{
                textDecoration: "underline",
              }}
            >
              Basic information
            </Typography>
            {isDisputed && <StatusPill type="error" label="In dispute" />}
          </Grid>

          <CaseNoteContainer>
            {/* CONSULTATION ID */}
            <Grid item>
              <Grid
                item
                container
                flexDirection="column"
                gap={1}
                className={classes.subItem}
              >
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Consultation ID:
                  </Typography>
                </Grid>
                <Grid item className={classes.info}>
                  {_id ? (
                    <Grid item container gap={1}>
                      <Typography className={classes.info}>
                        {trucateString(_id, 10, "front")}
                      </Typography>
                      <Copy text={_id} name="Consultation ID" />
                    </Grid>
                  ) : (
                    <Typography className={classes.info}> No value</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/*  CONSULTATION DATE */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>
                    Consultation Date:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {dateMoment(createdAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* CONSULTATION TYPE */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>
                    Consultation type:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>{type}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* CONTACT MEDIUM */}
            <Grid item>
              <Grid
                item
                container
                flexDirection="column"
                gap={1}
                className={classes.subItem}
              >
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Contact medium:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {contactMedium}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/*  CONSULTATION DURATION */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>
                    Consultation duration:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {formatSecs(consultationDuration)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/*  CONSULTATION STATUS */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>Status:</Typography>
                </Grid>
                <Grid item>
                  <StatusPill
                    label={status ? status : "No status"}
                    type={
                      status === "cancelled"
                        ? "error"
                        : status === "completed"
                        ? "success"
                        : status === "ongoing"
                        ? "normal"
                        : "unknown"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {/*  REASON FOR CANCELATION */}
            {status === "cancelled" && (
              <Grid item>
                <Grid container className={classes.subItem} gap={1}>
                  <Grid item>
                    <Typography className={classes.title}>
                      Reason for cancelation:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.info}>
                      {reason ? reason : "No reason."}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {!isNotDeputed && (
              <>
                {/*  DEPUTED STATUS */}
                <Grid item>
                  <Grid container className={classes.subItem} gap={1}>
                    <Grid item>
                      <Typography className={classes.title}>
                        Dispute status:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <StatusPill
                        label={disputeStatus ? disputeStatus : "No status"}
                        type={
                          disputeStatus === "pending"
                            ? "warning"
                            : disputeStatus === "resolved"
                            ? "success"
                            : "normal"
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/*  DEPUTED REASON */}
                <Grid item>
                  <Grid
                    item
                    container
                    flexDirection="column"
                    gap={1}
                    className={classes.subItem}
                  >
                    <Grid item>
                      <Typography variant="body3" className={classes.title}>
                        Dispute reason:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.info}>
                        {disputeReason ? disputeReason : "No reason"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* DEPUTE RESOLVE REASON */}
                {disputeStatus === "cancelled" ||
                  (disputeStatus === "resolved" && (
                    <Grid item>
                      <Grid
                        item
                        container
                        flexDirection="column"
                        gap={1}
                        className={classes.subItem}
                      >
                        <Grid item>
                          <Typography variant="body3" className={classes.title}>
                            Dispute resolved reason:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.info}>
                            {disputeResolvedReason
                              ? disputeResolvedReason
                              : "No reason"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </>
            )}

            {/*  CONSULTATION ACCEPTED  AT */}
            {appointmentAcceptedAt && (
              <Grid item>
                <Grid container className={classes.subItem} gap={1}>
                  <Grid item>
                    <Typography className={classes.title}>
                      Doctor accepted at:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.info}>
                      {timeMoment(appointmentAcceptedAt)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/*  CONSULATION STARTED AT  */}
            {appointmentStartedAt && (
              <Grid item>
                <Grid container className={classes.subItem} gap={1}>
                  <Grid item>
                    <Typography className={classes.title}>
                      Consultation started at:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.info}>
                      {timeMoment(appointmentStartedAt)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*  DECLINE REASON */}
            {declineReason && (
              <Grid item>
                <Grid container className={classes.subItem} gap={1}>
                  <Grid item>
                    <Typography className={classes.title}>
                      Decline reason:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.info}>
                      {declineReason}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </CaseNoteContainer>
        </Grid>

        {/* ===== CONSULTATION PATIENT INFORMATION ===== */}
        <Grid
          item
          container
          direction="column"
          className={classes.parentGridWrapper}
        >
          <Typography
            variant="h3"
            sx={{
              textDecoration: "underline",
              marginBottom: "2rem",
            }}
          >
            Patient information
          </Typography>

          <CaseNoteContainer
            item
            container
            flexWrap="wrap"
            display={{ lg: "grid", md: "grid", sm: "block" }}
            gridTemplateColumns={{ lg: "1fr 1fr 1fr" }}
            className={classes.item}
            alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
            rowGap={{ xs: "1.5rem", sm: "1.5rem", lg: "3rem" }}
            columnGap={{ xs: "1.5rem", sm: "1.5rem" }}
          >
            {/* PATIENT NAME */}
            <Grid item>
              <Grid
                item
                container
                flexDirection="column"
                gap={1}
                className={classes.subItem}
              >
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Patient name:
                  </Typography>
                </Grid>
                <Grid item className={classes.info}>
                  {patientData && Object.keys(patientData).length > 0 ? (
                    <Grid
                      item
                      container
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/patients/${patientData?._id}`);
                      }}
                    >
                      <Grid item marginRight={2}>
                        <Avatar
                          src={patientData?.image}
                          alt={`Display photo of the ${patientData?.firstName}`}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          className={`${classes.info} ${classes.underline}`}
                        >{`${patientData?.firstName} ${patientData.lastName}`}</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography className={classes.info}>No Patient</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/*  PATIENT JOINED */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>
                    Patient joined:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {patientJoined ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* PATIENT CONSULTATION SUCCESSFUL */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>
                    Consultation successful (Patient):
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {patientSatisfied ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* PATIENT END CALL REASON */}
            <Grid item>
              <Grid
                item
                container
                flexDirection="column"
                gap={1}
                className={classes.subItem}
              >
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Patient end call reason:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {patientEndCommunicationReason
                      ? patientEndCommunicationReason
                      : "No reason"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/*  SYMPTOMS */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>Symptoms:</Typography>
                </Grid>
                <Grid item container wrap="wrap" gap={1}>
                  {symptoms ? (
                    symptoms.map((symptom, index) => {
                      return (
                        <StatusPill
                          key={index}
                          label={symptom.name}
                          type="normal"
                        />
                      );
                    })
                  ) : (
                    <Typography variant="body1">No symptoms</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/*  DISCOMFORT LEVEL */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>
                    Discomfort level:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {discomfortLevel ? discomfortLevel : "No level."}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/*  FIRST TIME NOTICE */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>
                    First time notice:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {firstNotice}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* DESCRIPTION */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>
                    Description:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {(description && description) || "No comment."}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* RATING */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>
                    Consultation rating:
                  </Typography>
                </Grid>
                <Grid item>
                  <Rating
                    name="read-only"
                    value={rating?.score || 0}
                    readOnly
                  />
                  <Typography className={classes.info}>
                    {(rating && rating?.review) || "No comment."}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CaseNoteContainer>
        </Grid>

        {/* ===== CONSULTATION DOCTOR INFORMATION ===== */}
        <Grid
          item
          container
          direction="column"
          className={classes.parentGridWrapper}
        >
          <Typography
            variant="h3"
            sx={{
              textDecoration: "underline",
              marginBottom: "2rem",
            }}
          >
            Doctor information
          </Typography>

          <CaseNoteContainer
            item
            container
            flexWrap="wrap"
            display={{ lg: "grid", md: "grid", sm: "block" }}
            gridTemplateColumns={{ lg: "1fr 1fr 1fr" }}
            className={classes.item}
            alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
            rowGap={{ xs: "1.5rem", sm: "1.5rem", lg: "3rem" }}
            columnGap={{ xs: "1.5rem", sm: "1.5rem" }}
          >
            {/* DOCTOR NAME */}
            <Grid item>
              <Grid
                item
                container
                flexDirection="column"
                gap={1}
                className={classes.subItem}
              >
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Doctor name:
                  </Typography>
                </Grid>
                <Grid item className={classes.info}>
                  {doctorData && Object.keys(doctorData).length > 0 ? (
                    <Grid
                      item
                      container
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (category === "hospital")
                          history.push(`/hcps/${doctorData?._id}`);
                      }}
                    >
                      <Grid item marginRight={2}>
                        <Avatar
                          src={doctorData?.image}
                          alt={`Display photo of the ${doctorData?.firstName}`}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          className={`${classes.info} ${
                            category === "hospital" ? classes.underline : ""
                          }`}
                        >{`${doctorData?.firstName} ${doctorData.lastName}`}</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography className={classes.info}>
                      No Doctor data
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/*  DOCTOR JOINED */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography className={classes.title}>
                    Doctor joined:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {doctorJoined ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* DOCTOR END CALL REASON */}
            <Grid item>
              <Grid
                item
                container
                flexDirection="column"
                gap={1}
                className={classes.subItem}
              >
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Doctor end call reason:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.info}>
                    {doctorEndCommunicationReason
                      ? doctorEndCommunicationReason
                      : "No reason"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* DOCTOR'S NOTE */}
            {doctorNote && (
              <Grid item>
                <Grid container className={classes.subItem} gap={1}>
                  <Grid item>
                    <Typography className={classes.title}>
                      Doctor&apos;s note:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.info}>
                      {doctorNote ? doctorNote : "No doctor's note."}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/*  DIAGNOSIS */}
            {diagnosis && (
              <Grid item>
                <Grid container className={classes.subItem} gap={1}>
                  <Grid item>
                    <Typography className={classes.title}>
                      Diagnosis:
                    </Typography>
                  </Grid>
                  <Grid item container wrap="wrap" gap={1}>
                    {diagnosis ? (
                      diagnosis.map((diagnosis, index) => {
                        return (
                          <StatusPill
                            key={index}
                            label={diagnosis?.ailment}
                            type="normal"
                          />
                        );
                      })
                    ) : (
                      <Typography variant="body1">No symptoms</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/*  PRESCRIPTIONS AND TEST REFERRAL */}
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                {prescription && (prescription || []).length > 0 && (
                  <Grid item>
                    <EditDelBtn
                      type="edit"
                      text="View prescription(s)"
                      noIcon={true}
                      onClick={() => setIsOpen(true)}
                    />
                  </Grid>
                )}
                <Grid item>
                  {loadingTestRef && <Loader />}
                  {(testRefArr || []).length > 0 && (
                    <EditDelBtn
                      type="edit"
                      text="View Test referral(s)"
                      noIcon={true}
                      onClick={() => setTestRefOpen(true)}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CaseNoteContainer>
        </Grid>
      </Grid>

      <PrescriptionModal
        isOpen={isOpen}
        handleDialogClose={() => setIsOpen(false)}
        consultationInfo={data?.getConsultation || {}}
      />

      <TestRefModal
        isOpen={testRefOpen}
        handleDialogClose={() => setTestRefOpen(false)}
        consultationInfo={data?.getConsultation || {}}
        testRefInfo={testRefData?.getReferral || {}}
      />
    </>
  );
};

export default CaseNotes;
